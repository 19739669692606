import React, { useCallback, useEffect, useRef } from "react";
import { GoogleApiWrapper } from "google-maps-react";
import { googleApiKey } from "utils/config";
import { getStatesGeometry, getZipcodesGeometry } from "./utils";
import { STATE_FULL_NAME } from "utils/constants";

const Map = ({ google, locationType, location }) => {
  const map = useRef(null);
  const mapLayers = useRef(null);
  const marker = useRef(null);
  const radiusCircle = useRef(null);
  const infoWindows = useRef(null);

  const initMap = useCallback(() => {
    map.current = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 39.661, lng: -98.048 },
      zoom: 4,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
    });
  }, [google.maps.Map]);

  useEffect(() => {
    initMap();
  }, [initMap]);

  useEffect(() => {
    if (location) {
       // Debugging log
      switch (locationType) {
        case "state":
          resetMap();
          location.states &&
            !!location.states.length &&
            getStatesGeometry(location.states).then((res) =>
              draw(location.states.map((l) => STATE_FULL_NAME[l]), res)
            );
          break;
        case "zip":
          resetMap();
          if (location.zips && !!location.zips.length) {
            getZipcodesGeometry(location.zips).then((res) =>
              draw(location.zips.map((l) => l + " postal code"), res)
            );
          }
          break;
        case "local":
          resetMap();
          

          for (let i = 0; i < location.length; i++) {
            resetMap();
            const loc = location[i];
           
            
        
            drawMarkerWithLatLng(parseFloat(location[i].lat), parseFloat(location[i].lng), location[i].radius);
        }
         
          break;
        default:
          resetMap();
      }
    }
  }, [location, locationType]);

  const resetMap = () => {
    marker.current && marker.current.setMap(null);
    radiusCircle.current && radiusCircle.current.setMap(null);
    if (infoWindows.current) {
      infoWindows.current.forEach((i) => {
        i.close();
      });
    }
    if (mapLayers.current) {
      for (var i = 0; i < mapLayers.current.length; i++) {
        map.current.data.remove(mapLayers.current[i]);
      }
    }
  };

  const draw = (locations, geoJson) => {
    map.current.data.setStyle({
      fillColor: "#FF5252",
      strokeWeight: 1,
      fillOpacity: 0.5,
      strokeColor: "#FF0000",
    });

    infoWindows.current = [];
    mapLayers.current = map.current.data.addGeoJson(geoJson);
    let bounds = null;
    const geocoder = new google.maps.Geocoder();

    locations.forEach((location) => {
      geocoder.geocode({ address: location, region: "US" }, function (
        results,
        status
      ) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results instanceof Array && results.length > 0) {
            const viewport = results[0].geometry.viewport;
            if (bounds) {
              bounds = bounds.extend(viewport.getSouthWest());
              bounds = bounds.extend(viewport.getNorthEast());
            } else {
              bounds = viewport;
            }
            map.current.fitBounds(bounds, 20);
          }
        }
      });
    });
  };

  const drawMarker = (address, radius) => {
    console.log("Drawing marker for address:", address); // Debugging log
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: address, region: "US" }, function (
      results,
      status
    ) {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results instanceof Array && results.length > 0) {
          const geometry = results[0].geometry;
          drawMarkerWithLatLng(
            geometry.location.lat(),
            geometry.location.lng(),
            radius
          );
        }
      } else {
        console.error("Geocoding failed:", status);
      }
    });
  };

  const drawMarkerWithLatLng = (lat, lng, radius) => {
    console.log("Drawing marker with lat/lng:", lat, lng, radius); // Debugging log
    const position = { lat, lng };
    marker.current = new google.maps.Marker({
      position,
      icon: {
        url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
        scaledSize: new google.maps.Size(32, 32),
      },
    });
    marker.current.setMap(map.current);
    const circle = new google.maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      center: position,
      radius: radius * 1609.34, // Convert miles to meters
    });
    circle.setMap(map.current);
    map.current.fitBounds(circle.getBounds(), 20);
    radiusCircle.current = circle;
  };

  return (
    <div
      id="map"
      style={{
        height: 320,
        marginTop: 20,
      }}
    />
  );
};

export default GoogleApiWrapper({
  apiKey: googleApiKey,
})(Map);
