import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import LocationLocal from './components/LocationLocal';
import LocationState from './components/LocationState';
import LocationZip from './components/LocationZip';
import Map from './components/Map';
import OfferWizard from '../OfferWizard'

const locationTypes = {
  global: 'GLOBAL',
  country: 'US',
  state: 'state',
  zips: 'zip',
  local: 'local',
};

const OfferAudience = ({ back, onClose, onComplete, values }) => {
  const businessInfo = useSelector((state) => state.business.companyInfo);
  const [locationType, setLocationType] = useState(get(values, 'audience.type') || locationTypes.country);
  const [location, setLocation] = useState(values.audience || { type: 'US' });
  const [error, setError] = useState(false);
 console.log(values);
 console.log(values.offer);
  const isFormValid = useCallback(() => {
    return (
      (locationType === locationTypes.global && location.type) ||
      (locationType === locationTypes.country && location.type) ||
      (locationType === locationTypes.state && location.states && !!location.states.length) ||
      (locationType === locationTypes.zips && location.zips && !!location.zips.length) ||
      (locationType === locationTypes.local && location.local && location.local.length > 0) // Allow multiple addresses
    );
  }, [locationType, location]);

  useEffect(() => {
    if (error) {
      setError(!isFormValid());
    }
  }, [error, isFormValid]);

  const handleChange = (e) => {
    const val = e.target.value;
    if (val === locationTypes.global) {
      setLocation({ type: locationTypes.global });
    }
    if (val === locationTypes.country) {
      setLocation({ type: locationTypes.country });
    }
    if (val === locationTypes.local) {
      setLocation({
        local: [
          {
            address: `${businessInfo.street} ${businessInfo.city} ${businessInfo.zipCode}`,
            lat: businessInfo.latitude,
            lng: businessInfo.longitude,
            radius: 10, // Default radius
          },
        ],
        type: "local",
      });
    }
    if (val === locationTypes.state) {
      setLocation({
        states: [],
        type: locationTypes.state,
      });
    }
    if (val === locationTypes.zips) {
      setLocation({
        zips: [],
        type: locationTypes.zips,
      });
    }

    setError(false);
    setLocationType(val);
  };

  const handleSubmit = () => {
    if (!isFormValid()) {
      setError(true);
      return;
    }

    // Submit the location directly with all selected locations
    onComplete(location);
  };

  return (
    <div>
      <Box mb={4}>
        <Typography variant="h6" component="h2">
          Where are your customers?
        </Typography>
        <Typography variant="body2" component="p" color="textSecondary">
          Find new customers in the areas you serve
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid container item lg={5} sm={12}>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" error={false}>
              <FormLabel component="legend">Designate the area you want to cover</FormLabel>
              <RadioGroup onChange={handleChange} value={locationType}>
                <FormControlLabel
                  value={locationTypes.global}
                  control={<Radio />}
                  label="Global"
                />
                <FormControlLabel
                  value={locationTypes.country}
                  control={<Radio />}
                  label="United States"
                />
                <FormControlLabel
                  value={locationTypes.state}
                  control={<Radio />}
                  label="Specific State(s)"
                />
                <FormControlLabel
                  value={locationTypes.zips}
                  control={<Radio />}
                  label="Specific Zip Code(s)"
                />
                <FormControlLabel
                  value={locationTypes.local}
                  control={<Radio />}
                  label="A radius around your business(es)"
                />
              </RadioGroup>
            </FormControl>
            <Box mt={2}>
              {locationType === locationTypes.global && (
                <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px' }}>
                  Estimated Cost Per Click: $0.95
                </Typography>
              )}
              {locationType === locationTypes.country && (
                <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px' }}>
                  Estimated Cost Per Click: $0.65
                </Typography>
              )}
              {locationType === locationTypes.state && (
                <LocationState
                  error={error}
                  value={location.states}
                  updateLocation={(val) => {
                    setLocation({
                      states: val,
                      type: locationTypes.state,
                    });
                  }}
                />
              )}
              {locationType === locationTypes.zips && (
                <LocationZip
                  error={error}
                  value={location.zips}
                  updateLocation={(val) => {
                    console.log(val)
                    setLocation({
                      zips: val,
                      type: locationTypes.zips,
                    });
                  }}
                />
              )}
              {locationType === locationTypes.local && (
                <LocationLocal
                  error={error}
                  value={location.local}
                  updateLocation={(val) => {
                    console.log("Updating location in OfferAudience:", val);
                    setLocation({
                      local: val, // Pass array of objects directly
                      type: "local",
                      
                    });
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid item lg={7} xs={12} style={{ marginBottom: 20 }}>
          <Map
            locationType={locationType}
            location={
              locationType === locationTypes.local
                ? location.local // Pass the array of local locations
                : location
            }
          />
        </Grid>
      </Grid>
      <Box mt={2} mb={1} display="flex" justifyContent="space-between">
        <Box>
          <Button onClick={onClose}>Cancel</Button>
        </Box>
        <Box>
          <Button color="primary" onClick={back}>
            Back
          </Button>
          <Button
            color="primary"
            onClick={handleSubmit}
            variant="contained"
            type="submit"
          >
            Next
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default OfferAudience;
