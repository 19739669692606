import React, { useState, useEffect } from 'react';
import {
  Box,
  Checkbox,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Slider,
} from '@material-ui/core';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { exportedOfferId as offerId } from '../../../OfferWizard';

const LocationLocal = ({ error, updateLocation }) => {
  const [addresses, setAddresses] = useState([]);
  const [selectedAddresses, setSelectedAddresses] = useState([]);
  const [cpc, setCpc] = useState(0.45);
  const [loading, setLoading] = useState(true);
  const businessId = useSelector((state) => state.business.businessId);

  useEffect(() => {
    axios
      .get(`/api/business/${businessId}/addresses`)
      .then((response) => {
        setAddresses(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching addresses:', error);
        setLoading(false);
      });
  }, [businessId]);

  useEffect(() => {
    if (addresses.length > 0) {
      axios
        .get(`/api/business/${businessId}/${offerId}/SelectedAddresses`)
        .then((response) => {
          const selectedData = response.data.map((item) => ({
            addressId: item.addressId,
            radius: item.radius || 15,
          }));
          setSelectedAddresses(selectedData);
        })
        .catch((error) =>
          console.error('Error fetching selected addresses:', error)
        );
    }
  }, [addresses, businessId, offerId]);

  useEffect(() => {
    const hasLargeRadius = selectedAddresses.some((item) => item.radius > 15);
    const calculatedCpc =
      selectedAddresses.length > 1 || hasLargeRadius ? 0.65 : 0.45;
    setCpc(calculatedCpc);
  }, [selectedAddresses]);

  const handleCheckboxChange = (event, addressId) => {
    const isChecked = event.target.checked;

    let updatedSelectedAddresses = [...selectedAddresses];
    if (isChecked) {
      const matchedAddress = selectedAddresses.find(
        (item) => item.addressId === addressId
      );
      updatedSelectedAddresses.push({
        addressId,
        radius: matchedAddress ? matchedAddress.radius : 15,
      });
    } else {
      updatedSelectedAddresses = updatedSelectedAddresses.filter(
        (item) => item.addressId !== addressId
      );
    }

    setSelectedAddresses(updatedSelectedAddresses);
    updateLocationData(updatedSelectedAddresses);
  };

  const handleRadiusChange = (event, newValue, addressId) => {
    const updatedSelectedAddresses = selectedAddresses.map((item) =>
      item.addressId === addressId ? { ...item, radius: newValue } : item
    );

    setSelectedAddresses(updatedSelectedAddresses);
    updateLocationData(updatedSelectedAddresses);
  };

  const handleTextChange = (event, addressId) => {
    const newRadius = event.target.value;

    const updatedSelectedAddresses = selectedAddresses.map((item) =>
      item.addressId === addressId ? { ...item, radius: newRadius } : item
    );

    setSelectedAddresses(updatedSelectedAddresses);
  };

  const handleTextBlur = (event, addressId) => {
    let newRadius = parseInt(event.target.value, 10) || 0;

    if (newRadius < 2) newRadius = 2;
    if (newRadius > 100) newRadius = 100;

    const updatedSelectedAddresses = selectedAddresses.map((item) =>
      item.addressId === addressId ? { ...item, radius: newRadius } : item
    );

    setSelectedAddresses(updatedSelectedAddresses);
    updateLocationData(updatedSelectedAddresses);
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      const allSelectedAddresses = addresses.map((address) => ({
        addressId: address.addressId,
        radius: 15,
      }));
      setSelectedAddresses(allSelectedAddresses);
      updateLocationData(allSelectedAddresses);
    } else {
      setSelectedAddresses([]);
      updateLocationData([]);
    }
  };

  const areAllSelected =
    addresses.length > 0 &&
    selectedAddresses.length === addresses.length;

  const updateLocationData = (selectedAddresses) => {
    const locationArray = selectedAddresses.map((item) => {
      const address = addresses.find((addr) => addr.addressId === item.addressId);
      return {
        address: `${address.street}, ${address.city}, ${address.state} ${address.zipCode}`,
        lat: address.latitude,
        lng: address.longitude,
        radius: item.radius,
        addressId: address.addressId,
      };
    });
    updateLocation(locationArray);
  };

  if (loading) {
    return (
      <Box
        mt={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress />
        <Typography variant="body2" color="textSecondary" mt={2}>
          Loading, please wait...
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box mt={4} style={{ width: '80%' }}>
        <Typography variant="body2" color="textSecondary">
          Estimated Cost Per Click: ${cpc.toFixed(2)}
        </Typography>
      </Box>

      <Box mt={8} style={{ width: '230%' }}>
        <Typography variant="body2" color="textSecondary">
          Select business addresses and set radius:
        </Typography>
        <TableContainer
          component={Paper}
          style={{
            maxHeight: 300,
            overflow: 'scroll', // Ensures scrollbar is always visible
            marginTop: '16px',
            border: '1px solid #ccc',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
          }}
        >
          <style>
            {`
              ::-webkit-scrollbar {
                width: 12px; /* Width of the scrollbar */
              }
              ::-webkit-scrollbar-thumb {
                background-color: #888; /* Thumb color */
                border-radius: 10px; /* Rounded corners */
              }
              ::-webkit-scrollbar-thumb:hover {
                background-color: #555; /* Darker on hover */
              }
              ::-webkit-scrollbar-track {
                background-color: #f0f0f0; /* Track color */
              }
            `}
          </style>
          <div style={{ width: '100%', height: '100%', overflow: 'scroll' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Checkbox
                        checked={areAllSelected}
                        onChange={handleSelectAll}
                      />
                      <Typography variant="body2" color="textPrimary">
                        Select All
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textPrimary">
                      Street
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textPrimary">
                      City
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textPrimary">
                      State
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textPrimary">
                      Zip Code
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textPrimary">
                      Radius (miles)
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addresses.map((address) => {
                  const selectedRadius = selectedAddresses.find(
                    (item) => item.addressId === address.addressId
                  )?.radius;

                  return (
                    <TableRow key={address.addressId}>
                      <TableCell>
                        <Checkbox
                          checked={selectedAddresses.some(
                            (item) => item.addressId === address.addressId
                          )}
                          onChange={(event) =>
                            handleCheckboxChange(event, address.addressId)
                          }
                        />
                      </TableCell>
                      <TableCell>{address.street}</TableCell>
                      <TableCell>{address.city}</TableCell>
                      <TableCell>{address.state}</TableCell>
                      <TableCell>{address.zipCode}</TableCell>
                      <TableCell>
                        {addresses.length <= 0 ? (
                          <Box>
                            <Slider
                              value={selectedRadius || 15}
                              onChange={(event, newValue) =>
                                handleRadiusChange(event, newValue, address.addressId)
                              }
                              min={2}
                              max={100}
                              step={1}
                              style={{ width: '150px' }}
                            />
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              align="center"
                            >
                              Radius: {selectedRadius || 15} miles
                            </Typography>
                          </Box>
                        ) : (
                          <TextField
                            value={selectedRadius || ''}
                            onChange={(event) =>
                              handleTextChange(event, address.addressId)
                            }
                            onBlur={(event) =>
                              handleTextBlur(event, address.addressId)
                            }
                            type="number"
                            inputProps={{
                              min: 2,
                              max: 100,
                              step: 1,
                            }}
                            style={{ width: '80px' }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
        {error && selectedAddresses.length === 0 && (
          <Typography variant="body2" color="error" mt={2}>
            Please select at least one business address.
          </Typography>
        )}
      </Box>
    </>
  );
};

export default LocationLocal;
