import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Card,
  Button,
  Typography,
  CardHeader,
  CardActions,
  CardContent,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import AddressSearch from '../AddressSearch';

const BusinessAddresses = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [addresses, setAddresses] = useState([]);
  const [newAddress, setNewAddress] = useState({
    street: '',
    city: '',
    state: '',
    zipCode: '',
    countryCode: 'US',
  });
  const [open, setOpen] = useState(false);
  const [currentEditIndex, setCurrentEditIndex] = useState(null);
  const [latitude, setLatitude] = useState(null); // Autofilled based on selection
  const [longitude, setLongitude] = useState(null); // Autofilled based on selection

  const businessId = useSelector((state) => state.business.businessId);

  useEffect(() => {
    if (businessId) {
      axios
        .get(`/api/business/${businessId}/addresses`)
        .then((response) => {
          setAddresses(response.data);
        })
        .catch((error) => {
          enqueueSnackbar('Error fetching addresses', { variant: 'error' });
        });
    }
  }, [businessId]);

  const handleClickOpen = (index = null) => {
    if (index !== null) {
      const address = addresses[index];
      setNewAddress({
        street: address.street,
        city: address.city,
        state: address.state,
        zipCode: address.zipCode,
        countryCode: 'US',
      });
      setLatitude(address.latitude);
      setLongitude(address.longitude);
      setCurrentEditIndex(index);
    } else {
      setNewAddress({ street: '', city: '', state: '', zipCode: '', countryCode: 'US' });
      setLatitude(null);
      setLongitude(null);
      setCurrentEditIndex(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewAddress({ street: '', city: '', state: '', zipCode: '', countryCode: 'US' });
    setLatitude(null);
    setLongitude(null);
    setCurrentEditIndex(null);
  };

  const parseAddress = (address) => {
    const addressParts = address.split(',');
    const street = addressParts[0]?.trim();
    const city = addressParts[1]?.trim();
    const stateZip = addressParts[2]?.trim();
    const [state, zipCode] = stateZip ? stateZip.split(' ') : [null, null];

    return {
      street,
      city,
      state,
      zipCode,
    };
  };

  const handleAddressSelect = (v) => {
    const parsedAddress = parseAddress(v.fullAddressResult);
    setNewAddress({
      ...newAddress,
      street: parsedAddress.street,
      city: parsedAddress.city,
      state: parsedAddress.state,
      zipCode: parsedAddress.zipCode,
    });
    setLatitude(v.latitude);
    setLongitude(v.longitude);
  };

  const handleAddOrEditAddress = () => {
    if (newAddress.street && newAddress.city && newAddress.state && newAddress.zipCode) {
      const addressData = {
        ...newAddress,
        latitude,
        longitude,
      };

      if (currentEditIndex !== null) {
        const addressId = addresses[currentEditIndex].addressId;
        axios
          .put(`/api/business/${businessId}/addresses/${addressId}`, addressData)
          .then((response) => {
            const updatedAddresses = [...addresses];
            updatedAddresses[currentEditIndex] = response.data;
            setAddresses(updatedAddresses);
            handleClose();
          })
          .catch((error) => {
            console.error('Error updating address:', error);
          });
      } else {
        axios
          .post(`/api/business/${businessId}/addresses`, addressData)
          .then((response) => {
            setAddresses([...addresses, response.data]);
            handleClose();
          })
          .catch((error) => {
            console.error('Error adding address:', error);
          });
      }
    } else {
      enqueueSnackbar('Please fill out all fields before saving the address');
    }
  };

  const handleDeleteAddress = (index) => {
    const addressId = addresses[index].addressId;
    axios
      .delete(`/api/business/${businessId}/addresses/${addressId}`)
      .then(() => {
        const updatedAddresses = addresses.filter((_, i) => i !== index);
        setAddresses(updatedAddresses);
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          const linkedOffers = error.response.data.linkedOffers || [];
          const message = error.response.data.message || 'An error occurred.';
          const offerHeadlines = linkedOffers.map((offer) => offer.headline_1).join(', ');
          enqueueSnackbar(`${message} Linked offers: ${offerHeadlines}`, { variant: 'error' });
        } else {
          enqueueSnackbar('An error occurred while deleting the address', { variant: 'error' });
        }
      });
  };

  return (
    <Box mb={2}>
      <Card>
        <CardHeader subheader="Additional Business Address" />
        <CardContent
          style={{
            maxHeight: '200px',
            overflowY: 'scroll', // Ensure the scrollbar is always visible
          }}
        >
          <style>
            {`
              ::-webkit-scrollbar {
                width: 10px;
              }
              ::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 5px;
              }
              ::-webkit-scrollbar-thumb:hover {
                background: #555;
              }
              ::-webkit-scrollbar-track {
                background: #f0f0f0;
              }
            `}
          </style>
          {addresses.length > 0 ? (
            addresses.map((address, index) => (
              <Box
                key={index}
                mb={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body2" style={{ fontSize: '0.875rem' }}>
                  {`${address.street}, ${address.city}, ${address.state} - ${address.zipCode}`}
                </Typography>
                <Box>
                  <Button color="primary" onClick={() => handleClickOpen(index)}>
                    Edit
                  </Button>
                  <Button color="secondary" onClick={() => handleDeleteAddress(index)}>
                    Delete
                  </Button>
                </Box>
              </Box>
            ))
          ) : (
            <Typography color="textSecondary" variant="body2">
              No addresses added yet.
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <Button color="primary" onClick={() => handleClickOpen()}>
            Add Address
          </Button>
        </CardActions>
      </Card>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{currentEditIndex !== null ? 'Edit Address' : 'Add a New Address'}</DialogTitle>
        <DialogContent>
          <AddressSearch onSelect={handleAddressSelect} label="Your business address" value={newAddress.street} />
          <TextField
            fullWidth
            label="City"
            value={newAddress.city}
            onChange={(e) => setNewAddress({ ...newAddress, city: e.target.value })}
            margin="normal"
          />
          <TextField
            fullWidth
            label="State"
            value={newAddress.state}
            onChange={(e) => setNewAddress({ ...newAddress, state: e.target.value })}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Zip Code"
            value={newAddress.zipCode}
            onChange={(e) => setNewAddress({ ...newAddress, zipCode: e.target.value })}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddOrEditAddress} color="primary">
            {currentEditIndex !== null ? 'Save Changes' : 'Save Address'}
          </Button>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BusinessAddresses;
